import React from "react"
import { Link } from "gatsby"

import SiteLayout from "../components/layout/site"

import GeniusIcon from "../../static/svgs/genius.svg"
import WalletIcon from "../../static/svgs/wallet.svg"
import FocusIcon from "../../static/svgs/focus.svg"
import BargraphIcon from "../../static/svgs/bargraph.svg"
import BookopenIcon from "../../static/svgs/book-open.svg"
import MobileIcon from "../../static/svgs/mobile.svg"

export default ({ data }) => (
  <SiteLayout title="Developer Portal">
    <div className="container-fluid jumbotron" id="hero">
      <div className="hero-content">
        <h1 className="display-4">Liquid Open API</h1>
        <p className="lead">
          An interoperable platform that makes payments easier, faster and more
          rewarding
        </p>
        <div className="my-5">
          <Link
            to="/docs/introduction"
            className="btn btn-solid btn-md px-3 mx-2"
            style={{ borderRadius: "23px" }}
          >
            Learn more
          </Link>
          <Link
            to="/docs/introduction"
            className="btn btn-link btn-md text-white px-3 mx-2"
            style={{ borderRadius: "23px" }}
          >
            Contact Us
          </Link>
        </div>
      </div>
      <div className="particles-layer" id="particles-js" />
    </div>

    <div className="section section-lg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <img src={GeniusIcon} height="80px" alt="icon" />
            <h2 className="py-3">Interoperability</h2>
          </div>
          <div className="col-md-10 offset-md-1 text-center pb-5">
            <p
              className="lead text-muted"
              style={{ fontWeight: 200, lineHeight: 1.4 }}
            >
              As a platform, Liquid Pay is a combination of multiple innovative
              services. By offering a secure and robust open payment framework,
              we empower developers, merchants and banks to quickly deploy
              multiple interoperable services.
            </p>
          </div>
        </div>

        <div className="row m-t-40">
          <div className="col-md-3">
            <img src={WalletIcon} height="40px" alt="icon" />
            <h3 className="py-3 h5">Digital Wallet</h3>
            <p>
              Stores consumer's credit, debit, pre-paid, gift and membership
              cards, as well as vouchers, on the one single app
            </p>
          </div>
          <div className="col-md-3">
            <img src={FocusIcon} height="40px" alt="icon" />
            <h3 className="py-3 h5">QR driven workflow</h3>
            <p>
              Frictionless, standardized payment experience including
              Pay@Counter, Pay@Table, Pay@Bar and more...
            </p>
          </div>
          <div className="col-md-3">
            <img src={BargraphIcon} height="40px" alt="icon" />
            <h3 className="py-3 h5">Data Analytics</h3>
            <p>
              We provide powerful data analytics, as well as fraud detection to
              generate information from 'data'
            </p>
          </div>
          <div className="col-md-3">
            <img src={MobileIcon} height="40px" alt="icon" />
            <h3 className="py-3 h5">Digital LifeStyle</h3>
            <p>
              We are integrated with online systems to deliver a seamless
              customer experience across different platforms
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="section section-lg bg-light">
      <div className="container">
        <div className="text-center">
          <h2 className="text-primary">Start your journey</h2>
          <p className="text-muted">
            Time to start creating a great product with us !
          </p>
        </div>

        <div className="row">
          <div className="col-md-4 p-20">
            <div className="box text-center box-primary animate bounceIn">
              <div className="content">
                <img src={BookopenIcon} height="60px" alt="icon" />
                <h3 className="h4 text-primary py-2">Documentation</h3>
                <p>Topics and information that every developer should know</p>
              </div>
              <div>
                <Link
                  to="/docs/introduction"
                  className="btn btn-primary-outline"
                >
                  Read document
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-20">
            <div className="box text-center box-primary animate bounceIn">
              <div className="content">
                <img src={BookopenIcon} height="60px" alt="icon" />
                <h3 className="h4 text-primary py-2">API Explorer</h3>
                <p>Playground that help you exploring our APIs</p>
              </div>
              <div>
                <Link to="/apis" className="btn btn-primary-outline">
                  Learn about APIs
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-20">
            <div className="box text-center box-primary animate bounceIn">
              <div className="content">
                <img src={BookopenIcon} height="60px" alt="icon" />
                <h3 className="h4 text-primary py-2">Get Supported</h3>
                <p>
                  Got any technical question? Get in touch and we will get back
                  to you as soon as we can
                </p>
              </div>
              <div>
                <Link to="/support" className="btn btn-primary-outline">
                  Get Help
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="row">
        <div className="offset-md-2 col-md-8 pt-5">
          <div className="blockquote blockquote-lg text-center">
            <h2>Ready to get started ?</h2>
            <p>
              <span className="text-muted">OR</span> have a question that we can
              help?
            </p>
            <div>
              <a
                href="mailto:techsupport@liquidpay.com"
                className="btn btn-primary-outline b-transparent"
              >
                Contact our Tech Team
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SiteLayout>
)
